import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CrystalLightbox } from'./dom.service'

@Component({
	selector: 'crystal-gallery', 
	template: `<div #contentWrapper [hidden]="isNgContent">
    <ng-content></ng-content>
</div>

<masonry-layout 
    class="cg-wrapper"
    *ngIf="!isNgContent" 

    [max-height]="masonryMaxHeight" 
    [gutter]="masonryGutter"
    [masonry]="masonryState"
    [class-name]="'cg-image'"
    [images]="images"
    (events)="handleMasonryLayoutEvents($event)"></masonry-layout>`,
    styles: [`:host{display:block;width:100%}:host .cg-wrapper{display:block;width:100%;overflow:hidden}:host .cg-wrapper.cg-show{height:auto;position:relative;display:table}:host .cg-image img{cursor:pointer;width:100%}`],
})
export class GalleryComponent {

    @Input('images') images: any;
    @Input('config') config: any = {};

    @ViewChild('contentWrapper') contentWrapper: ElementRef;

    get isNgContent(){
        return this.contentWrapper.nativeElement.children.length > 0;
    }

    get masonryState(){
        return this.config.masonry;
    }

    get masonryMaxHeight(){
    	return this.config.masonryMaxHeight;
    }

    get masonryGutter(){
    	return this.config.masonryGutter;
    }

	constructor(
		private lightbox: CrystalLightbox,
		private elementRef: ElementRef
	) {}

	showLightbox(images: any, i: number){
        this.config.index = i;
		this.lightbox.open(images, this.config);
	}

    handleMasonryLayoutEvents(event){
        this.showLightbox(this.images, event.index);
    }
}